import React from "react";
import Form from "../Form/Form";
import { useNavigate } from "react-router-dom";
import auth from "../../utils/auth";
import api from "../../utils/api";

export default function SignIn(props) {
    const refLogin = React.useRef();
    const refPassword = React.useRef();
    const navigate = useNavigate();

    function handlerSubmit(e) {
        e.preventDefault();

        auth
            .login({
                login: refLogin.current.value,
                password: refPassword.current.value,
            })
            .then((data) => {
                console.log(data.success)
                if (data.success) {
                    navigate('/')
                };
            })
            .catch((err) => {
                alert(err);
            });
    }

    React.useEffect(() => {
        api
            .get()
            .then((res) => {
                console.log(res)
                props.setLoggedIn(true)
                navigate('/')
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])

    return (
        <Form onSubmit={handlerSubmit}>
            <div className="login_block">
                <input className="inp_login" id="login_login" placeholder="Логин" name="login" type="text" ref={refLogin} required/>
                <input className="inp_login" id="login_passwd" placeholder="Пароль" name="passwd" type="password" ref={refPassword} required/>
                <button type="submit">Войти</button>
            </div>
        </Form>
    );
}