import { useToImage } from "@hcorta/react-to-image"
import React from "react"
import { CurrentFormData } from "../../contexts/CurrentFormData"

export default function GenerateField(props) {
    const { ref, getPng } = useToImage()
    const currentData = React.useContext(CurrentFormData)
    console.log(currentData)

    if (currentData !== null) {
        if (currentData.title !== '' || currentData.txt !== '' || currentData.img !== null) {
            return (
                <div className="generate-block">
                    <button type="button" className="gen-download" onClick={getPng}>Скачать</button>
                    <div ref={ref} className="gen-field">
                        <h1 className="gen-title">
                            {currentData.title}
                        </h1>
                        <div className="child-div">
                            <div className="txt-block">
                                <p className="gen-txt">
                                    {currentData.txt}
                                </p>
                            </div>
                            <div className="img-div">
                                <img src={currentData.img} className="gen-img" />
                                <p className="gen-footer">t.me/unitedhw</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <></>
        );
    }
}