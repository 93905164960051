class Api {
    constructor(options) {
        this._url = options.url;
        this._headers = options.headers;
    }

    _checkResponse(res) {
        if (res.ok) {
            return res.json();
        }
        return Promise.reject(`СМЭРТ: ${res.status}`);
    }

    get() {
        return fetch(this._url + "/", {
            headers: this._headers,
            credentials: 'include'
        })
            .then(this._checkResponse)
    }
}

const api = new Api({
    url: "https://picgen.fewser.ru/api",
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

export default api;