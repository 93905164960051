import React from 'react';
import { CurrentFormData } from '../contexts/CurrentFormData';
import './App.css';
import GenerateField from './GenerateField/GenerateField';
import GenerateInput from './GenerateInput/GenerateInput';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import SignIn from './SignIn/SignIn';
import api from '../utils/api';
import SignUp from './SignUp/SignUp';

function App() {
  const nav = useNavigate();
  const [currentData, setCurrentValue] = React.useState(null)
  const [loggedIn, setLoggedIn] = React.useState(false);
  

  React.useEffect(() => {
    if (loggedIn) {
      nav('/')
    }
  }, [loggedIn, nav])

  return (
    <Routes>
      <Route path='/login' element={<SignIn setLoggedIn={setLoggedIn} />} />
      <Route path='/register' element={<SignUp />} />
      <Route path='/' element={
        <ProtectedRoute loggedIn={loggedIn}>
          <CurrentFormData.Provider value={currentData}>
            <div className="App">
              <GenerateInput setCurrentValue={setCurrentValue} />
              <GenerateField />
            </div>
          </CurrentFormData.Provider>
        </ProtectedRoute>} />
    </Routes>
  );
}

export default App;
