import React from "react";
import Form from "../Form/Form";
import auth from "../../utils/auth";

export default function SignUp(props) {
    const loginRef = React.useRef()
    const passwordRef = React.useRef()

    function handlerSubmit(e) {
        e.preventDefault()

        auth
            .registration({
                login: loginRef.current.value,
                password: passwordRef.current.value
            })
            .then((res) => {
                alert(res.status)
            })
            .catch(err => alert(err)) 
    }

    return (
    <Form onSubmit={handlerSubmit}>
        <div className="login_block">
            <input className="inp_login" placeholder="Логин" type="text" ref={loginRef} required />
            <input className="inp_login" type="password" placeholder="Пароль" ref={passwordRef} required/>
            <button type="submit">Создать</button>
        </div>
    </Form>
    )
}