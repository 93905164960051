import React from "react";
import Form from "../Form/Form";

export default function GenerateInput(props) {
    const [title, setTitle] = React.useState('')
    const [baseTxt, setBaseTxt] = React.useState('')
    const [img, setImg] = React.useState(null)

    function handleButtonClick(e) {
        e.preventDefault()
        props.setCurrentValue({
            title: title,
            txt: baseTxt,
            img: img
        })
        console.log({
            title: title,
            txt: baseTxt,
            img: img
        })
    }

    function handleDwnImgClick(e) {
        setImg(URL.createObjectURL(e.target.files[0]))
    }

    function handlerClear() {
        setTitle('')
        setBaseTxt('')
        setImg(null)
        props.setCurrentValue(null)
    }

    return (
        <Form onSubmit={handleButtonClick}>
            <div className="form-container">
                <div className="input-contaier">
                    <h2 className="title-input">
                        Заголовок
                    </h2>
                    <input required type="text" id="title" onChange={(e) => setTitle(e.target.value)} value={title} />
                </div>
                <div className="input-contaier">
                    <h2 className="title-input">
                        Основной текст
                    </h2>
                    <textarea required className="base-txt" onChange={(e) => setBaseTxt(e.target.value)} value={baseTxt} />
                </div>
                <div className="input-contaier">
                    <h2 className="title-input">
                        Картинка
                    </h2>
                    <input required className='img-inp' id='img-inp' name="img-inp" type="file" onChange={handleDwnImgClick} />
                    <label htmlFor='img-inp' id='img-inp-label'>Загрузить картинку</label>
                </div>
                <div className="button-block">
                    <button type="submit">Генерировать</button>
                    <button type='clear' onClick={handlerClear}>Очистить</button>
                </div>
            </div>
        </Form>
    );
}